// Libraries
import React from "react"
import { Link, graphql } from "gatsby"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"
import { Parallax } from "react-parallax"
import { FaWrench, FaHardHat, FaPhone } from "react-icons/fa"

// Components (Updated to scoped path)
import Associations from "@one-builder/gatsby-theme-ob-master/src/components/associations/associations"
import Layout from "@one-builder/gatsby-theme-ob-master/src/components/layout/layout"
import MarkdownContent from "@one-builder/gatsby-theme-ob-master/src/components/content"
import LatestWork from "@one-builder/gatsby-theme-ob-master/src/components/home/latest-work"
import LatestNews from "@one-builder/gatsby-theme-ob-master/src/components/home/latest-news"
import FeaturedServices from "@one-builder/gatsby-theme-ob-master/src/components/home/featured-services"
import ServiceDropdown from "@one-builder/gatsby-theme-ob-master/src/components/home/service-dropdown"
import TestimonialSlider from "@one-builder/gatsby-theme-ob-master/src/components/home/testimonial-slider"
import NewsletterSignup from "@one-builder/gatsby-theme-ob-master/src/components/home/newsletter-signup"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
import setSeoData from "@one-builder/gatsby-theme-ob-master/src/helpers/setSeoData"

export default ({ data }) => {
  // Get metadata
  const metadata = useGetTokens()

  // Get Home Page Data
  const { home } = data

  let seo_data = setSeoData(home.frontmatter)

  return (
    <Layout seo_data={seo_data}>
      <section id={styles.ctaSection}>
        <Parallax
          bgImage={"/images/work2.jpg"}
          strength={-200}
          className={styles.featuredServicesParallax}
        >
          <div className={styles.container}>
            <div id={styles.ctaContain}>
              <a href="/services/">
                <span>
                  <FaWrench />
                </span>
                <h2>View Services</h2>
              </a>
              <a href="/about/">
                <span>
                  <FaHardHat />
                </span>
                <h2>About Us</h2>
              </a>
              <a href="/contact/">
                <span>
                  <FaPhone />
                </span>
                <h2>Contact</h2>
              </a>
            </div>
          </div>
        </Parallax>
      </section>

      <section className={`${styles.row} ${styles.row1}`}>
        <div className={styles.container}>
          <MarkdownContent
            content={convertTokens(home.frontmatter.welcomeSection, metadata)}
          />
        </div>
      </section>
      <LatestWork />
      <section className={`${styles.row} ${styles.row2}`}>
        <div className={styles.container}>
          <MarkdownContent
            content={convertTokens(home.frontmatter.aboutSection, metadata)}
          />
          <ServiceDropdown />
        </div>
      </section>

      <LatestNews url="news" />

      <section
        id={styles.testimonials}
        className={`${styles.row} ${styles.row3}`}
      >
        <div className={styles.container}>
          <TestimonialSlider />
        </div>
      </section>
      <FeaturedServices />

      <Associations />

      <section
        id={styles.hpRemodeling}
        className={`${styles.row} ${styles.row4}`}
      >
        <div className={styles.container}>
          <div className={styles.flexWrapper}>
            <div id={styles.containLeft}>
              <img
                src="/images/windows-and-doors.jpg"
                alt="Remodeling Services"
              />
            </div>
            <div id={styles.containRight}>
              <MarkdownContent
                content={convertTokens(
                  home.frontmatter.contentSection1,
                  metadata
                )}
              />
              <div className={styles.buttonContain}>
                <Link
                  className={styles.btn}
                  to={`/remodeling/`}
                  title="Remodeling Services"
                >
                  View Remodeling Services
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id={styles.newsletterSignup}>
        <div className={`${styles.container} ${styles.flexWrapper}`}>
          <div className={styles.newsletterContent}>
            <h2>Stay in touch</h2>
            <p>Subscribe to receive our latest news and offers.</p>
          </div>
          <NewsletterSignup />
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query {
    home: markdownRemark(frontmatter: { title: { eq: "Home" } }) {
      id
      frontmatter {
        title
        is_active
        meta {
          title
          description
          page_visible
        }
        template
        ctaSection
        welcomeSection
        aboutSection
        contentSection1
        contentSection2
        contentSection3
        contentSection4
        contentSection5
        testimonials {
          name
          review
        }
      }
    }
  }
`
